enum API {
	STUDENTS = '/api/students/main',
	STUDENTS_BY_ID = '/api/students/main/',
	STUDENT_PAYMENTS = '/api/students/payments',
	STUDENTS_ATC = '/api/students/main/studentsATCData',
	STUDENTS_ARCHIVE = '/api/students/main/uploadStudentInfo',
	STUDENT_PAYMENTS_BY_ID = '/api/students/payments/',
	STUDENT_DOCUMENTS = '/api/students/files',
	STUDENT_DOCUMENTS_BY_ID = '/api/students/files/',
	STUDENT_APPLICATIONS = '/api/students/applications',
	STUDENT_APPLICATIONS_BY_ID = '/api/students/applications/',
	EXPORT_STUDENTS_CARDS_PDF = '/api/students/main/export/studentCardPdf',
	SURVEYS = '/api/students/survey',
	SURVEYS_BY_ID = '/api/students/survey/',
	DISCOUNTS = '/api/students/discounts',
	ATTENDANCE = '/api/students/attendance',
	ATTENDANCE_BY_ID = '/api/students/attendance/',
	CERTIFICATE = '/api/students/certificates',
	CERTIFICATE_BY_ID = '/api/students/certificates/',
	DIPLOMA_WORKS = '/api/students/diplomaWorks',
	DIPLOMA_WORKS_BY_ID = '/api/students/diplomaWorks/',
	DISTRIBUTE_DIPLOMA_WORKS = '/api/students/diplomaWorks/defence/distribute',
	DOCUMENTS = '/api/documents',
	STUDENTS_SEND_PASSWORD_RESET_MAILS = '/api/students/main/changeStudentPasswordBulk',
	PAYMENTS_UPLOAD_THE_LAST_BALANCE = '/api/students/payments/uploadBalances',
	PAYMENTS_UPLOAD_THE_PAYMENT_AMOUNT = '/api/students/payments/uploadPayments',
	STUDENT_SYNCHRONIZATION = '/api/students/synchronization'
}

export default API;
